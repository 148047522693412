import {
  CarAvailabilityStatus,
  DamageReportFilteringStatus,
  DamageReportFilteringType,
  ReportsSortBy,
} from 'src/api/globalTypes';
import { UserSignInMutation_userSignIn_configuration } from 'src/apps/NewDriverApp/graphql/mutation/__generated__/UserSignInMutation';
import { CarInfo_car } from 'src/apps/NewDriverApp/graphql/queries/__generated__/CarInfo';
import { CarList_carList_cars } from 'src/apps/NewDriverApp/graphql/queries/__generated__/CarList';
import { DamageReports_allDamageReports_reports } from 'src/apps/NewDriverApp/graphql/queries/__generated__/DamageReports';

export interface UserData {
  id: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  displayName: string | null;
  location: string[];
}

export enum LoginError {
  UNAUTHORIZED = 'unauthorized',
  UNKNOWN = 'unknown',
}

export interface ResetPasswordReducerData {
  loading?: boolean;
  error?: null | string;
}

export interface ChangePasswordReducerData {
  loading?: boolean;
  error?: null | string;
}

export interface UserReducer {
  loading: boolean;
  error: null | LoginError;
  role: string | null;
  data: UserData;
  operations: string[];
  configuration: UserSignInMutation_userSignIn_configuration | null;
  isUserSelectedLanguage: boolean;
  resetPassword: ResetPasswordReducerData;
  changePassword: ChangePasswordReducerData;
}

export interface FleetManagerDecodedToken {
  role: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  location: string[];
  exp: number;
}

export enum LoginFormFieldName {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export interface LoginData {
  [LoginFormFieldName.EMAIL]: string;
  [LoginFormFieldName.PASSWORD]: string;
}

export enum ResetPasswordFormFieldName {
  EMAIL = 'email',
}

export interface ResetPasswordData {
  [ResetPasswordFormFieldName.EMAIL]: string;
}

export enum NewPasswordFormFieldName {
  NEW_PASSWORD = 'password',
  REPEAT_NEW_PASSWORD = 'passwordConfirm',
}

export interface NewPasswordData {
  [NewPasswordFormFieldName.NEW_PASSWORD]: string;
  [NewPasswordFormFieldName.REPEAT_NEW_PASSWORD]: string;
}

export enum CarListFormFields {
  LICENSE_PLATE = 'licensePlate',
  AVAILABILITY_FILTER = 'availability',
}
export enum DamageListFormFields {
  STATUS = 'status',
  LICENSE_PLATE = 'q',
  INSURANCE_COVERAGE_TYPE = 'insuranceCoverageType',
  TYPE = 'type',
  AVAILABILITY = 'availability',
}

export interface CarListFilters {
  page: number;
  [CarListFormFields.AVAILABILITY_FILTER]?: CarAvailabilityStatus[];
  [CarListFormFields.LICENSE_PLATE]?: string;
}

export interface DamageListFilters {
  page: number;
  [DamageListFormFields.STATUS]?: DamageReportFilteringStatus[];
  carId?: string;
  sortBy?: ReportsSortBy;
  [DamageListFormFields.LICENSE_PLATE]?: string;
  [DamageListFormFields.TYPE]?: DamageReportFilteringType[];
}

export interface DamageListFilterData {
  categoryName: string;
  options: string[];
}

export interface DamageListFilter {
  isLoading: boolean;
  error: Error | null;
  data: DamageListFilterData[];
}
export interface DamageFilterData {
  damageStatusFilter: DamageListFilter;
}

export enum ListError {
  INITIAL = 'initial',
  UNKNOWN = 'unknown',
}

export interface CarListReducer {
  isLoading: boolean;
  error: ListError | null;
  data: Omit<CarList_carList_cars, '__typename'>[];
  filters: CarListFilters;
  total: number | null;
}

export type DamageReportListItem = Omit<DamageReports_allDamageReports_reports, '__typename'>;

export interface DamageListReducer {
  isLoading: boolean;
  error: ListError | null;
  data: DamageReportListItem[];
  filters: DamageListFilters;
  total: number | null;
  filterData: DamageFilterData;
}

export type CarInfo = Omit<CarInfo_car, '__typename'>;

export interface CarInformation {
  isLoading: boolean;
  error: Error | null;
  data: CarInfo | null;
}

export interface CarStatus {
  isSuccess: boolean;
  error: Error | null;
}

export interface ReportNavigation {
  isLoading: boolean;
  error: Error | null;
}
export interface CarDetailsReducer {
  carInformation: CarInformation;
  reportNavigation: ReportNavigation;
}

export interface CarReducer {
  carList: CarListReducer;
  carDetails: CarDetailsReducer;
}

export enum CarDetailsFormInputs {
  AVAILABILITY = 'availability',
}

export interface DamageReducer {
  damageList: DamageListReducer;
}

export interface DamageListForm {
  [DamageListFormFields.STATUS]?: DamageReportFilteringStatus[];
  [DamageListFormFields.LICENSE_PLATE]?: string;
}

export type DamageListAvailableFormFilters =
  | DamageListFormFields.LICENSE_PLATE
  | DamageListFormFields.STATUS;

export interface DamageListProps {
  data: DamageListReducer['data'];
  isLoading: boolean;
  filters: DamageListReducer['filters'];
  onUpdateFilters: (values: OnUpdateFiltersArgs) => void;
  damageListFilterProps: {
    values: DamageListFilterData[];
    isLoadingFilter?: boolean;
    onFetchDamageStatusData: () => void;
  };
  isDisplayLicensePlate?: boolean;
  availableFilters?: DamageListAvailableFormFilters[];
  error: null | ListError;
  fetchMore: ({ isInitialCall }: { isInitialCall?: boolean | undefined }) => void;
}

export interface UseDamageListForm {
  onUpdateFilters: DamageListProps['onUpdateFilters'];
  filters: DamageListProps['filters'];
  isLoading: DamageListProps['isLoading'];
  data: DamageListProps['data'];
  availableFilters: DamageListAvailableFormFilters[];
  error: null | ListError;
}

export interface UseDamageList {
  initialFilters?: Partial<DamageListForm>;
  shouldFetchOnInit?: boolean;
  shouldResetFiltersOnInit?: boolean;
  shouldResetDataOnInit?: boolean;
}

export interface OnUpdateFiltersArgs {
  isInitialCall?: boolean;
  params: Partial<DamageListFilters>;
}

export type FilteredByProperty<
  OriginalType,
  PropertyNameToFilter extends keyof OriginalType,
> = Omit<OriginalType, PropertyNameToFilter> & {
  [K in PropertyNameToFilter]: NonNullable<OriginalType[PropertyNameToFilter]>;
};
