import { i18n as I18nInterface } from 'i18next';
import { HttpBackendOptions, RequestCallback, RequestResponse } from 'i18next-http-backend';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { DataSource } from 'src/api/globalTypes';
import { LANG, LOCIZE_URL } from 'src/constants';
import { getLogger } from 'src/services/app-insights';
import { TRACE_MESSAGE } from 'src/services/app-insights/types';

import {
  FAILED_TO_FETCH_LOCIZE_ERROR_STATUS,
  FAILED_TO_FETCH_LOCIZE_ERROR_MESSAGE,
  FAILED_TO_FETCH_LOCIZE_RETRIES,
  FAILED_TO_FETCH_LOCIZE_RETRIES_DELAY_MS,
  LOCALLY_LOADED_NAMESPACES,
  FAILED_TO_FETCH_LOCIZE_EXCEPTION_PREFIX,
} from './constants';
import { LocizeAppName } from './types';
import { AppConfiguration_appConfiguration } from '../../graphql/queries/__generated__/AppConfiguration';
import { setAssetsData } from '../../redux/actions';
import {
  appLocalesConfiguration,
  getIsContentLoadingFailedAssetsData,
} from '../../redux/selectors';
import { getState, store } from '../../redux/store';
import { AppEmbeddedEvents } from '../../redux/types/AppState';
import { EmbeddedService } from '../embedded';
import { fetchWithRetry } from '../fetch/withRetry';

const LOCALISATION_HELPERS_LOGGER_CONTEXT_IDENTIFIER = 'i18n/helpers';

const appInsights = getLogger(LOCALISATION_HELPERS_LOGGER_CONTEXT_IDENTIFIER);

const isFailedToFetchLocizeError = (err: any): boolean =>
  err.status === FAILED_TO_FETCH_LOCIZE_ERROR_STATUS ||
  err.message === FAILED_TO_FETCH_LOCIZE_ERROR_MESSAGE;

const handleSuccessTranslations = async (
  res: Response,
  options: HttpBackendOptions,
  urlObj: URL,
  callback: RequestCallback,
) => {
  const translations = await res.json();

  if (isEmpty(translations)) {
    const params = urlObj.pathname.substring(1).split('/');
    const [projectId, version, lang, namespace] = params;

    const payload = {
      projectId,
      version,
      lang,
      namespace,
      sessionId: options?.queryStringParams?.sessiondId,
    };

    appInsights.trackTrace(
      {
        message: TRACE_MESSAGE.LOCIZE_EMPTY_NAMESPACE,
      },
      payload,
    );
  }

  const successResponse: RequestResponse = {
    status: res.status,
    data: translations,
  };

  callback(null, successResponse);
};

const locizeFetchNamespace = async (
  options: HttpBackendOptions,
  url: string,
  _: unknown,
  callback: RequestCallback,
) => {
  const urlObj = new URL(url);
  const urlWithQuery = urlObj.toString();

  try {
    if (options.queryStringParams) {
      Object.entries(options.queryStringParams).forEach((entry: [string, string]) => {
        urlObj.searchParams.append(...entry);
      });
    }

    const res = await fetch(urlWithQuery);

    await handleSuccessTranslations(res, options, urlObj, callback);
  } catch (err: any) {
    if (isFailedToFetchLocizeError(err)) {
      if (!store.getState().assets.isContentLoading) {
        store.dispatch(setAssetsData({ isContentLoading: true }));
      }

      await fetchWithRetry({
        operation: () => fetch(urlWithQuery),
        delay: FAILED_TO_FETCH_LOCIZE_RETRIES_DELAY_MS,
        retries: FAILED_TO_FETCH_LOCIZE_RETRIES,
      })
        .then(res => handleSuccessTranslations(res, options, urlObj, callback))
        .catch(err => {
          callback(err, { status: 400, data: {} });

          appInsights.trackException({
            exception: err,
            properties: { operation: `${FAILED_TO_FETCH_LOCIZE_EXCEPTION_PREFIX}: ${err.message}` },
          });
        })
        .finally(() => {
          if (store.getState().assets.isContentLoading) {
            store.dispatch(setAssetsData({ isContentLoading: false }));
          }
        });
    } else {
      callback(err, { status: 400, data: {} });
    }
  }
};

export const handleLanguageChanged = (i18n: I18nInterface) => (lng: string) => {
  document.documentElement.setAttribute('lang', lng);
  document.documentElement.setAttribute('dir', i18n.dir());

  EmbeddedService.postMessage({
    type: AppEmbeddedEvents.LANGUAGE_CHANGED,
    payload: lng,
  });
};

export const handleInitialized = (i18n: I18nInterface) => () => {
  const state = getState();

  const configurationLocales = appLocalesConfiguration(state);
  const isContentLoadingFailed = getIsContentLoadingFailedAssetsData(state);

  if (!isContentLoadingFailed) {
    for (const namespace of LOCALLY_LOADED_NAMESPACES) {
      if (i18n.hasLoadedNamespace(namespace)) {
        for (const currentLocale of configurationLocales) {
          i18n.removeResourceBundle(currentLocale, namespace);
        }

        i18n.reloadResources(configurationLocales, namespace);
      }
    }
  }
};

export const handleFailedLoading = () => {
  const isContentLoadingFailed = getIsContentLoadingFailedAssetsData(store.getState());

  if (!isContentLoadingFailed) {
    store.dispatch(setAssetsData({ isContentLoadingFailed: true }));
  }
};

export const initEmbeddedLanguageListener = (i18n: I18nInterface) => {
  EmbeddedService.subscribe(AppEmbeddedEvents.CHANGE_LANGUAGE, payload => {
    i18n.changeLanguage(payload);
  });
};

export const getLocizeConfig = (
  apiNameList: string[],
  sessionId: string,
  customLocizeProjectIds?: string[] | null,
): HttpBackendOptions[] => {
  const version = process.env.REACT_APP_LOCIZE_VERSION || 'production';

  const defaultProjectIDsList = apiNameList.map(
    apiName => process.env[`REACT_APP_LOCIZE_${apiName}_PROJECT_ID`] || '',
  );

  const tenantCustomProjectIDsList = (customLocizeProjectIds || []).map(projectId => projectId);
  const projectIDsLists = [...tenantCustomProjectIDsList, ...defaultProjectIDsList];

  const projectConfigs = projectIDsLists.map(projectId => {
    return {
      loadPath: `${LOCIZE_URL}/${projectId}/${version}/{{lng}}/{{ns}}`,
      queryStringParams: { sessionId },
      request: locizeFetchNamespace,
    };
  });

  return projectConfigs;
};

export const getLocizeAppName = (source: DataSource): LocizeAppName | null => {
  switch (source) {
    case DataSource.BODYSHOP_APPLICATION: {
      return LocizeAppName.PRE_INSPECTION;
    }
    case DataSource.FLEET_MANAGER_APPLICATION: {
      return LocizeAppName.MANAGER;
    }
    default: {
      return null;
    }
  }
};

export const getAppLocales = (appConfiguration: AppConfiguration_appConfiguration) => {
  const lang = appConfiguration.lang || LANG.DE;
  const languages = (Array.isArray(lang) ? lang : [lang]).filter(Boolean);

  const defaultLocales: LANG[] = [];
  const locales = [...(appConfiguration.locales || defaultLocales), ...languages];

  return uniq(locales);
};
