import { combineReducers } from '@reduxjs/toolkit';

import { otherAccidentPartyReducer } from 'src/apps/NewDriverApp/flows/fleet/accident/redux/reducers/other-accident-party-reducer';

import { accidentAdditionalQuestionsReducer } from './accident-additional-questions-reducer';
import { accidentCarConditionReducer } from './accident-car-condition-reducer';
import { accidentDriverInformationReducer } from './accident-driver-information-reducer';
import { accidentPoliceReportReducer } from './accident-police-report-reducer';
import { accidentReasonsReducer } from './accident-reasons-reducer';
import { accidentResponsibilityReducer } from './accident-responsibility-reducer';
import { accidentSceneReducer } from './accident-scene-reducer';
import { saveAccidentReportReducer } from './save-accident-report-reducer';

export const accidentReportReducer = combineReducers({
  addtionalQuestions: accidentAdditionalQuestionsReducer,
  accidentReasons: accidentReasonsReducer,
  accidentScene: accidentSceneReducer,
  accidentResponsibility: accidentResponsibilityReducer,
  accidentDriverInformation: accidentDriverInformationReducer,
  carCondition: accidentCarConditionReducer,
  policeReport: accidentPoliceReportReducer,
  otherAccidentParty: otherAccidentPartyReducer,
  savingStatus: saveAccidentReportReducer,
});

export type AccidentReportReducer = ReturnType<typeof accidentReportReducer>;
