import { isFleetManagerApp } from 'src/apps/NewDriverApp/utils/isFleetManagerApp';

import { TRACKING_IDENTITY_EVENT, TrackingIdentity } from './tracking-config.utils';
import { AppSegmentType } from '../api/globalTypes';

const HEAP_FLEET_TRACKING_ID = process.env.REACT_APP_HEAP_FLEET_TRACKING_ID;

function initTrackingId() {
  (window as { heap?: unknown }).heap = (window as unknown as { heap: unknown }).heap || [];
  (window as unknown as { heap: any }).heap.load = function (e: unknown, t: unknown = {}) {
    (window as unknown as { heap: any }).heap.appid = e;
    (window as unknown as { heap: any }).heap.config = t;
    const r = document.createElement('script');
    r.type = 'text/javascript';
    r.async = !0;
    r.src = `https://cdn.heapanalytics.com/js/heap-${e}.js`;
    const a = document.getElementsByTagName('script')[0];
    a.parentNode?.insertBefore(r, a);
    for (
      let n = function (y: unknown) {
          return function (...rest: unknown[]) {
            (window as unknown as { heap: any }).heap?.push?.(
              [y].concat(Array.prototype.slice.call(rest, 0)),
            );
          };
        },
        p = [
          'addEventProperties',
          'addUserProperties',
          'clearEventProperties',
          'identify',
          'resetIdentity',
          'removeEventProperty',
          'setEventProperties',
          'track',
          'unsetEventProperty',
        ],
        o = 0;
      o < p.length;
      o += 1
    )
      (window as unknown as { heap: any }).heap[p[o]] = n(p[o]);
  };

  (window as unknown as { heap: any }).heap.load(HEAP_FLEET_TRACKING_ID);
}

function initHeap() {
  initTrackingId();

  document.addEventListener(TRACKING_IDENTITY_EVENT, ((e: CustomEvent<TrackingIdentity>) => {
    const identity = e.detail;
    const packages = {};
    identity.extra.packages?.map((packageName: string) => {
      return Object.assign(packages, { [`${packageName}_package`]: true });
    });

    const segments = {};
    identity.extra.segments?.map((segmentName: string) => {
      return Object.assign(segments, { [`${segmentName}_segment`]: true });
    });

    const userProperties = {
      fleetName: identity.extra.fleetName,
      status: identity.extra.status,
      application: identity.extra.application,
      customer: identity.extra.customer,
      isPool: identity.extra.isPool,
      ...packages,
      ...segments,
    };
    (window as any).heap.addUserProperties(userProperties);
  }) as EventListener);
}

export function heapTracking(segment: AppSegmentType) {
  if (segment === AppSegmentType.FLEETS && !isFleetManagerApp(segment) && HEAP_FLEET_TRACKING_ID) {
    initHeap();
  }
}
