import React from 'react';

import cn from 'classnames';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

import { LINK_ADORNMENT_SIZE_CLASS_MAPPER, LINK_TYPE } from './constants';
import styles from './Link.module.scss';
import { LinkTextType, LinkType } from './types';
import Text from '../Text';
import { TEXT_COLOR, TEXT_LINK_TYPE } from '../Text/constants';
import { TextColor } from '../Text/types';

// &zwnj; is used for the appropriate lineheight inheritance computation

// for the appropriate svg color inheritance use, for example: "stroke="currentColor"
// instead of using a hardcoded color

// figma: https://www.figma.com/file/VrbCFUoiMT1jxeKwlyc9Zx/App-Design-Style-Guide?node-id=1214%3A562&mode=dev

interface Props extends LinkProps, React.RefAttributes<HTMLAnchorElement> {
  size?: LinkTextType;
  type?: LinkType;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  color?: TextColor;
}

const Link: React.FC<Props> = ({
  to,
  children,
  className,
  disabled,
  startAdornment,
  endAdornment,
  color = TEXT_COLOR.UNDEFINED,
  type = LINK_TYPE.PRIMARY,
  size = TEXT_LINK_TYPE.LINK_MEDIUM,
  ...restProps
}) => {
  const adornmentSizeClassName = styles[LINK_ADORNMENT_SIZE_CLASS_MAPPER[size]];

  const colorClassName = cn({
    [styles.disabled]: disabled,
    [styles.primary]: type === LINK_TYPE.PRIMARY,
    [styles.secondary]: type === LINK_TYPE.SECONDARY,
  });

  const content = (
    <Text
      type={size}
      color={color}
      className={cn(styles.text, { [colorClassName]: color === TEXT_COLOR.UNDEFINED })}
    >
      {startAdornment && (
        <span className={cn(styles.startAdornment, adornmentSizeClassName)}>
          &zwnj;
          {startAdornment}
        </span>
      )}
      <span>{children}</span>
      {endAdornment && (
        <span className={cn(styles.endAdornment, adornmentSizeClassName)}>
          &zwnj;{endAdornment}
        </span>
      )}
    </Text>
  );

  if (disabled) {
    return <span className={cn(styles.link, className)}>{content}</span>;
  }

  return (
    <RouterLink className={cn(styles.link, className)} to={to} {...restProps}>
      {content}
    </RouterLink>
  );
};

export default Link;
