import { createAction } from '@reduxjs/toolkit';

import {
  AccidentResponsibilityCauser,
  AccidentThirdPartyInput,
  DamageReportDrivableType,
} from 'src/api/globalTypes';

import {
  AccidentAdditionalQuestionsReducer,
  AccidentDriverInformationReducer,
  AccidentPoliceReportReducer,
  AccidentSceneReducer,
} from './types';
import { DAMAGE_REASONS } from '../../../../constants';

// car condition page
export const accidentCarAllConditions = createAction('ACCIDENT_CAR_ALL_CONDITIONS');

export const setIsCarRoadworthy =
  createAction<Partial<DamageReportDrivableType>>('SET_IS_CAR_ROADWORTHY');

// accident scene page
export const accidentScenePageCompleted = createAction<AccidentSceneReducer>(
  'ACCIDENT_SCENE_PAGE_COMPLETED',
);

// accident police report page

export const accidentPoliceReportPageCompleted = createAction<AccidentPoliceReportReducer>(
  'ACCIDENT_POLICE_REPORT_PAGE_COMPLETED',
);

// accident responsibility page

export const accidentResponsibilityPageCompleted = createAction<{
  responsible: AccidentResponsibilityCauser;
}>('ACCIDENT_RESPONSIBILITY_PAGE_COMPLETED');

// accident driver information page

export const updateAccidentDriverInformationData = createAction<{
  data: AccidentDriverInformationReducer;
}>('UPDATE_ACCIDENT_DRIVER_INFORMATION_DATA');

export const setAccidentDriverInformationData = createAction<
  Partial<AccidentDriverInformationReducer>
>('SET_ACCIDENT_DRIVER_INFORMATION_DATA');

// accident reasons page
export const accidentReasonsPageCompleted = createAction('ACCIDENT_REASONS_PAGE_COMPLETED');

// otherAccidentPartyPage
export const otherAccidentPartyPageCompleted = createAction('OTHER_ACCIDENT_PARTY_PAGE_COMPLETED');

// accident overview page
export const accidentOverviewPageCompleted = createAction('ACCIDENT_OVERVIEW_PAGE_COMPLETED');

// accident report completing status
export const completeAccidentReport = createAction('COMPLETE_ACCIDENT_REPORT');
export const completeAccidentReportSuccess = createAction('COMPLETE_ACCIDENT_REPORT_SUCCESS');
export const completeAccidentReportFailure = createAction<{ error: Error }>(
  'COMPLETE_ACCIDENT_REPORT_FAILURE',
);

// accident reasons
export const setAccidentReasonDescription = createAction<string>('SET_ACCIDENT_REASON_DESCRIPTION');
export const selectAccidentReason = createAction<DAMAGE_REASONS>('SELECT_ACCIDENT_REASON');
export const unselectAccidentReason = createAction<DAMAGE_REASONS>('UNSELECT_ACCIDENT_REASON');
export const selectAccidentWithCarReason = createAction<DAMAGE_REASONS>(
  'SELECT_ACCIDENT_WITH_CAR_REASON',
);
export const unselectAccidentWithCarReason = createAction<DAMAGE_REASONS>(
  'UNSELECT_ACCIDENT_WITH_CAR_REASON',
);

// other accident party
export const setOtherAccidentPartyData = createAction<Partial<AccidentThirdPartyInput>>(
  'SET_OTHER_ACCIDENT_PARTY_DATA',
);
export const updateOtherAccidentPartyData = createAction<{
  data: AccidentThirdPartyInput;
}>('UPDATE_OTHER_ACCIDENT_PARTY_DATA');

// accident step
export const accidentStepCompleted = createAction('ACCIDENT_STEP_COMPLETED');

// accident additional questions page
export const setAccidentAdditionalQuestionsData = createAction<
  Partial<AccidentAdditionalQuestionsReducer>
>('SET_ACCIDENT_ADDITIONAL_QUESTIONS_DATA');
