import { ResourceManipulationMode } from 'src/apps/NewDriverApp/hooks/useExtendedPermissions/types';

const mapExtendedPermissionsActionToValues: Record<string, ResourceManipulationMode> = {
  update: true,
  view: 'view',
  delete: 'delete',
};

export const resolveValueByExtendedPermissionsAction = (
  action?: string,
): ResourceManipulationMode =>
  mapExtendedPermissionsActionToValues[
    action as keyof typeof mapExtendedPermissionsActionToValues
  ] ?? false;
