import { IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import qs from 'qs';

import { DRIVER_OVERVIEW_APP_ROOT_ROUTE } from 'src/apps/DriverOverviewApp/constants';
import { store as driverOverviewAppStore } from 'src/apps/DriverOverviewApp/redux/store';
import { PATH_HEADER } from 'src/apps/NewDriverApp/services/constants';
import { getLogger } from 'src/services/app-insights';

import { AppSegmentType } from './api/globalTypes';
import { getRFPath } from './api-url';
import { SESSION_ID } from './apps/NewDriverApp/constants';
import { measurePerformance, PERFORMANCE_MARK } from './apps/NewDriverApp/performance';
import { setTheme } from './apps/NewDriverApp/redux/actions';
import { PersistStorage } from './apps/NewDriverApp/redux/persist-service';
import { store as driverAppStore } from './apps/NewDriverApp/redux/store';
import { ThemeInterface } from './apps/NewDriverApp/theme/ThemeInterface';
import { API_URL, IS_CODE_BASE_AUTH, WEB_APPS, WebAppConfig } from './constants';
import { loadCookieScript } from './cookie-script';
import { track } from './metrics';
import applyCssVariabes from './utils/applyCssVariables';

measurePerformance(PERFORMANCE_MARK.APP_LOADER_LOADED);

const APP_LOADER_LOGGER_CONTEXT_IDENTIFIER = 'app-loader';

const appInsights = getLogger(APP_LOADER_LOGGER_CONTEXT_IDENTIFIER);

/**
 * Stores provided code to session storage which is used in routing decision after
 */
(function preserveCode() {
  const params = qs.parse(window.location.search.slice(1));
  if (params.code) {
    PersistStorage.getStorageService().setItem(IS_CODE_BASE_AUTH, 'true');
  }
})();

(function allocateSessionID() {
  const sessionId = PersistStorage.getStorageService().getItem(SESSION_ID);

  if (!sessionId) {
    PersistStorage.getStorageService().setItem(SESSION_ID, Date.now().toString());
  }
})();

async function fetchWebAppConfig() {
  const response = await fetch(`${API_URL}/internal/webapp/config`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      [PATH_HEADER]: getRFPath(),
    },
  });

  if (response.status !== 200) {
    throw new Error(await response.json());
  }

  return response.json();
}

// TODO: improve app mapping
function resolveAppName(config: WebAppConfig) {
  const { isPreinspection, isDigitalReception, isNewDriverApp } = config;

  if (
    document.location.href.includes('inspection-confirmation') ||
    document.location.href.includes('handover-confirmation')
  ) {
    return WEB_APPS.APPOINTMENT_CONFIRMATION_APP;
  }

  if (document.location.href.includes(DRIVER_OVERVIEW_APP_ROOT_ROUTE)) {
    return WEB_APPS.DRIVER_OVERVIEW_APP;
  }

  // in case both apps supported
  if (
    isPreinspection &&
    isDigitalReception &&
    PersistStorage.getStorageService().getItem(IS_CODE_BASE_AUTH)
  ) {
    return WEB_APPS.DIGITAL_RECEPTION_APP;
  }

  if (isDigitalReception || isNewDriverApp) {
    return WEB_APPS.DIGITAL_RECEPTION_APP;
  }

  throw new Error('Unable to determine application');
}

function resolveAppSegment(config: WebAppConfig) {
  return config.isDriverFacingApp ? AppSegmentType.FLEETS : AppSegmentType.BODYSHOPS;
}

const applyThemeConfig = (app: WEB_APPS, theme: ThemeInterface) => {
  const stores = {
    [WEB_APPS.DIGITAL_RECEPTION_APP]: driverAppStore,
    [WEB_APPS.DRIVER_OVERVIEW_APP]: driverOverviewAppStore,
    [WEB_APPS.APPOINTMENT_CONFIRMATION_APP]: null,
  };

  const store = stores[app];

  if (store) {
    driverAppStore.dispatch(setTheme(theme));
    applyCssVariabes(theme);
  }
};

/**
 * TODO: handle different error cases
 * Error cases
 * - network error - backend is down - network error page
 * - no configuration resolved - 404 page
 */
export async function loadApp() {
  try {
    const config = await fetchWebAppConfig();
    if (config.lang) {
      document.documentElement.setAttribute('lang', config.lang);
    }

    const app = resolveAppName(config);
    const segment = resolveAppSegment(config);
    applyThemeConfig(app, config.theme);

    track(segment);
    loadCookieScript();

    await import(`src/routes/${app}.tsx`);
  } catch (error) {
    appInsights.trackException({ exception: error as IExceptionTelemetry['exception'] });
    /* eslint-disable no-console */
    console.error('Unable to load application', error);
    /* eslint-enable no-console */
    // @ts-ignore
    await import(`src/routes/errors-app.tsx`);
  }

  return null;
}
