import { createAction } from '@reduxjs/toolkit';

import { LoginData } from '../types';

export const loginCompleted = createAction<LoginData>('LOGIN_COMPLETED');

export const userLogout = createAction('USER_LOGOUT');

export const createReportAsFleetManager = createAction('CREATE_REPORT_AS_FLEET_MANAGER');

export const goToCarDetails = createAction('GO_CAR_DETAILS');

export const goToCarFleetManagerMenu = createAction('GO_TO_CAR_FLEET_MANAGER_MENU');
