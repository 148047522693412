import motumHeaderLogo1x from 'src/img/logos/motum_new_driver_app_header.png';
import motumHeaderLogo2x from 'src/img/logos/motum_new_driver_app_header@2x.png';
import motumGeneralLogo1x from 'src/img/logos/motum_new_driver_app_login.png';
import motumGeneralLogo2x from 'src/img/logos/motum_new_driver_app_login@2x.png';

import { ThemeInterface } from './ThemeInterface';

export const BuiltInTheme: ThemeInterface = {
  properties: {
    // the colors from the design stylesheet
    // https://www.figma.com/file/VrbCFUoiMT1jxeKwlyc9Zx/App-Design-Style-Guide?type=design&node-id=1194-77&mode=design&t=6aQQdaFIt3k1l2Sn-0
    primary_accent: '#1f4ef3',
    notification_success: '#44a351',
    secondary_background_base: '#f9f9f9',
    primary_background_base: '#ffffff',
    primary_content: '#171717',
    secondary_content: '#6b6e85',
    secondary_border_con_low: '#cbccd6',
    secondary_state_disabled: '#eaeaef',
    primary_graphic: '#5a66e2',
    primary_motum_content: '#031135',
    secondary_graphic: '#ad6d97',
    secondary_background_alt: '#f8f7f9',
    notification_error: '#df3c18',
    notification_error_background: '#ffebe9',
    notification_warning: '#ee9600',
    notification_warning_background: '#fff5e5',
    notification_success_background: '#e6f8e8',
    notification_info: '#6a66d2',
    notification_info_background: '#eeeff9',
    secondary_border_con_medium: '#b1b2bc',
    overlay_background_con_medium: '#171717b2',

    // Radio button
    radio_button_checked_bg_color: 'var(--primary-accent)',

    // Checkbox
    checkbox_checked_bg_color: 'var(--primary-accent)',

    // Switcher
    switcher_checked_bg_color: 'var(--primary-accent)',

    // Photo input
    photo_input_radius: '12px',
    photo_input_label_color: 'var(--secondary-content)',

    // Input
    input_radius: '8px',

    // SquareButton
    square_button_radius: '8px',

    // Link
    link_primary_text_color: 'var(--primary-accent)',
    link_secondary_text_color: 'var(--secondary-content)',
    link_disabled_text_color: 'var(--secondary-border-con-medium)',

    // Common button
    button_radius: '32px',

    // Primary button
    button_primary_bg_color: 'var(--primary-accent)',
    button_primary_bg_disabled_color: 'var(--secondary-state-disabled)',
    button_primary_text_color: 'var(--primary-background-base)',
    button_primary_text_disabled_color: 'var(--secondary-border-con-medium)',
    button_primary_spinner_color: 'var(--primary-background-base)',

    // Secondary button
    button_secondary_text_color: 'var(--secondary-content)',
    button_secondary_text_active_color: 'var(--primary-content)',
    button_secondary_bg_color: 'var(--primary-background-base)',
    button_secondary_border_color: 'var(--secondary-content)',
    button_secondary_border_active_color: 'var(--primary-content)',
    button_secondary_spinner_color: 'var(--secondary-content)',
    button_secondary_bg_disabled_color: 'var(--secondary-state-disabled)',
    button_secondary_text_disabled_color: 'var(--secondary-border-con-medium)',

    // Car parts
    car_part_item_fill_color_primary: 'var(--secondary-border-con-medium)',
    car_part_item_fill_color_secondary: '#dfe1f3',
    car_part_item_fill_color_selected: 'var(--primary-graphic)',
    car_part_item_fill_color_damaged: 'var(--secondary-content)',
    car_part_item_fill_color_disabled: 'var(--secondary-state-disabled)',

    car_part_bubble_new_color: 'var(--status-new-color)',
    car_part_bubble_closed_color: 'var(--status-closed-color)',
    car_part_bubble_internally_repaired_color: 'var(--status-internally-repaired-color)',
    car_part_bubble_repair_postponed_color: 'var(--status-repair-postponed-color)',
    car_part_bubble_repair_requested_color: 'var(--status-repair-requested-border-color)',
    car_part_bubble_in_repair_color: 'var(--status-in-repair-color)',

    // Status labels and bubbles colors
    status_new_color: '#ef5b45',
    status_new_background_color: '#fcdeda66',
    status_closed_color: 'var(--secondary-content)',
    status_closed_background_color: '#f4f4f6',
    status_internally_repaired_color: 'var(--secondary-content)',
    status_internally_repaired_background_color: '#f4f4f6',
    status_repair_postponed_color: 'var(--primary-accent)',
    status_repair_postponed_background_color: '#d2dffe',
    status_repair_requested_color: '#0f2bae',
    status_repair_requested_background_color: '#f5f8ff',
    status_repair_requested_border_color: '#779bfb',
    status_in_repair_color: 'var(--notification-warning)',
    status_in_repair_background_color: '#fef5e6',

    // Onboarding
    splash_screen_bg_color: 'var(--primary-background-base)',

    // Appointments app
    appointment_font_family: 'DMSans',

    appointment_header_background_color: 'var(--primary-background-base)',
    appointment_header_text_color: 'var(--primary-content)',
    appointment_base_background_color: 'var(--secondary-background-base)',
    appointment_secondary_background_color: 'var(--primary-background-base)',
    appointment_active_background_color: 'var(--primary-accent)',
    appointment_active_button_background_color: 'var(--button-primary-bg-color)',
    appointment_inner_background_color: 'var(--secondary-background-base)',

    appointment_primary_text_color: 'var(--primary-content)',
    appointment_secondary_text_color: 'var(--secondary-content)',
    appointment_active_text_color: 'var(--primary-background-base)',
    appointment_disabled_text_color: 'var(--secondary-border-con-low)',

    appointment_primary_border_color: 'var(--secondary-border-con-low)',
    appointment_secondary_border_color: 'var(--secondary-state-disabled)',
  },
  logo: {
    header1x: motumHeaderLogo1x,
    header2x: motumHeaderLogo2x,
    general1x: motumGeneralLogo1x,
    general2x: motumGeneralLogo2x,
  },
  favicon: { url: '' },
};
