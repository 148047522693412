import React, { SyntheticEvent } from 'react';

import cx from 'classnames';

import styles from './ContentArea.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
  noMargin?: boolean;
}

const ContentArea: React.FC<Props> = ({ className, children, style, onClick, noMargin }) => {
  return (
    <div
      onClick={onClick}
      style={style}
      className={cx(styles.container, className, { [styles.noMargin]: noMargin })}
    >
      {children}
    </div>
  );
};

export { ContentArea };
