import { FunctionComponent } from 'react';

import { TFunction } from 'i18next';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';

import { BodyStyle, DamageReportImageType } from 'src/api/globalTypes';
import { ImageTypes, PHOTO_FLOWS, Orientation } from 'src/apps/NewDriverApp/constants';
import { i18n_namespace as i18nInteriorNamespace } from 'src/apps/NewDriverApp/flows/fleet/interior/DamagePhotos/i18n-namespace';
import { ImageState, ImageUploadStatus } from 'src/apps/NewDriverApp/redux/types/Types';
import { getIsImageUploadStarted } from 'src/utils/images/getIsImageUploadStarted';

import { DamagePhotosCameraContext } from '../../types';
import { findDamageReportImageByType } from '../../utils/helpers';
import lazyWithPreload from '../../utils/lazyWithPreload';

const RearRightVanViewOverlayPortrait = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-rear-right-diagonal-view-portrait-transporter'),
);
const RearRightVanViewOverlayLandscape = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-rear-right-diagonal-view-landscape-transporter'),
);
const FrontLeftVanViewOverlayPortrait = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-front-left-diagonal-view-portrait-transporter'),
);
const FrontLeftVanViewOverlayLandscape = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-front-left-diagonal-view-landscape-transporter'),
);
const FrontLeftViewOverlayLandscape = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-front-left-diagonal-view-landscape-SUV'),
);
const RearRightViewOverlayPortrait = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-rear-right-diagonal-view-portrait-suv'),
);
const RearRightViewOverlayLandscape = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-rear-right-diagonal-view-landscape-SUV'),
);
const FrontLeftViewOverlayPortrait = lazyWithPreload(
  () => import('../../assets/overlays/reexports/px-front-left-diagonal-view-portrait-suv'),
);

const FrontLeftViewLightTruckPlaceholder = lazyWithPreload(
  () => import('../../icons/FrontLeftViewLightTruckPlaceholder'),
);
const FrontLeftViewSedanPlaceholder = lazyWithPreload(
  () => import('../../icons/FrontLeftViewSedanPlaceholder'),
);
const FrontLeftViewTransporterPlaceholder = lazyWithPreload(
  () => import('../../icons/FrontLeftViewTransporterPlaceholder'),
);
const RearRightViewTransporterPlaceholder = lazyWithPreload(
  () => import('../../icons/RearRightViewTransporterPlaceholder'),
);
const RearRightViewLightTruckPlaceholder = lazyWithPreload(
  () => import('../../icons/RearRightViewLightTruckPlaceholder'),
);
const RearRightViewSedanPlaceholder = lazyWithPreload(
  () => import('../../icons/RearRightViewSedanPlaceholder'),
);

export type PhotoFlows = keyof typeof PHOTO_FLOWS;

type Mask = Record<ImageTypes, React.ReactNode>;

type MaskCollection = Record<Orientation, Partial<Mask>>;
interface GetCameraMask {
  imageType: ImageTypes;
  carBodyType: BodyStyle | null;
}

export const getCurrentFlow = (imageType?: DamageReportImageType) => {
  if (imageType) {
    return Object.values(PHOTO_FLOWS).find((imageTypes: DamageReportImageType[]) =>
      imageTypes.includes(imageType),
    ) as DamageReportImageType[];
  }

  return [];
};

export const getPhotosError = (
  images: ImageState<DamagePhotosCameraContext>[],
  flow: DamageReportImageType[],
) =>
  images.some(image => {
    const type = get(image, 'context.imageType');

    if (flow.includes(type)) {
      return image.uploadingStatus === ImageUploadStatus.FAILURE;
    }

    return false;
  });

export const getPhotosForbiddenError = (
  images: ImageState<DamagePhotosCameraContext>[],
  flow?: DamageReportImageType[],
) =>
  images.some(image => {
    const type = get(image, 'context.imageType');

    if (flow?.length) {
      return flow.includes(type)
        ? get(image.error, 'graphQLErrors[0].extensions.response.statusCode') === 403
        : false;
    }

    return get(image.error, 'graphQLErrors[0].extensions.response.statusCode') === 403;
  });

export const isAllRequiredImagesUploaded = ({
  images,
  isPhotoOptional,
  photoFlows = ['CAR_CONDITION', 'DAMAGE_PHOTOS'],
}: {
  images: ImageState[];
  isPhotoOptional?: boolean;
  photoFlows?: PhotoFlows[];
}) => {
  if (isPhotoOptional) {
    return true;
  }

  const requiredImagesTypes = photoFlows.flatMap(key => PHOTO_FLOWS[key]);

  const additionalPhotos = images.filter(findDamageReportImageByType(ImageTypes.ADDITIONAL));
  const isAdditionalPhotosUploadStarted =
    additionalPhotos.length >= 1 && additionalPhotos.every(getIsImageUploadStarted);

  const isRequiredImagesReady = isEqual(
    intersection(
      images.filter(getIsImageUploadStarted).map(({ context }) => get(context, 'imageType')),
      requiredImagesTypes,
    )
      .slice()
      .sort(),
    requiredImagesTypes.slice().sort(),
  );

  return additionalPhotos.length
    ? isRequiredImagesReady && isAdditionalPhotosUploadStarted
    : isRequiredImagesReady;
};

export const getIconType = (carBodyType: BodyStyle | null, imageType?: ImageTypes) => {
  const isLeftCarAngle = imageType === ImageTypes.FRONT_LEFT_ANGLE;

  if (carBodyType === BodyStyle.TRANSPORTER) {
    return isLeftCarAngle
      ? FrontLeftViewTransporterPlaceholder
      : RearRightViewTransporterPlaceholder;
  }
  if (carBodyType === BodyStyle.LIGHT_TRUCK) {
    return isLeftCarAngle ? FrontLeftViewLightTruckPlaceholder : RearRightViewLightTruckPlaceholder;
  }
  return isLeftCarAngle ? FrontLeftViewSedanPlaceholder : RearRightViewSedanPlaceholder;
};

export const getPhotoTip = (t: TFunction, cameraImageType?: ImageTypes) => {
  switch (cameraImageType) {
    case ImageTypes.FRONT_LEFT_ANGLE: {
      return t('damagePhotos:diagonalViews.frontLeftPhotoTip');
    }
    case ImageTypes.REAR_RIGHT_ANGLE:
      return t('damagePhotos:diagonalViews.rearRightPhotoTip');
    case ImageTypes.LEFT_DIAGONAL_VIEW: {
      return t('damagePhotos:damageView.leftDiagonalPhotoTip');
    }
    case ImageTypes.RIGHT_DIAGONAL_VIEW:
      return t('damagePhotos:damageView.rightDiagonalPhotoTip');
    case ImageTypes.ADDITIONAL:
      return t('damagePhotos:additionalPhotos.photoTip');
    case ImageTypes.INTERIOR_LEFT:
      return t(`${i18nInteriorNamespace}:cameraTip.${ImageTypes.INTERIOR_LEFT}`);
    case ImageTypes.INTERIOR_RIGHT:
      return t(`${i18nInteriorNamespace}:cameraTip.${ImageTypes.INTERIOR_RIGHT}`);
    case ImageTypes.ACCIDENT_REPORT:
      return t('damagePhotos:accidentPhotos.accidentReportPhotTip');
    case ImageTypes.POLICE_REPORT:
      return t('police-accident-report:camera.tip');
    case ImageTypes.ACCIDENT_DOCUMENT:
      return t('accidentDocuments:cameraTip');
    default:
      return '';
  }
};

export const getCameraMask = ({ imageType, carBodyType }: GetCameraMask) => {
  const isTransporterBodyType = carBodyType === BodyStyle.TRANSPORTER;

  const transporterMasks: MaskCollection = {
    [Orientation.PORTRAIT]: {
      [ImageTypes.FRONT_LEFT_ANGLE]: FrontLeftVanViewOverlayPortrait,
      [ImageTypes.REAR_RIGHT_ANGLE]: RearRightVanViewOverlayPortrait,
    },
    [Orientation.LANDSCAPE]: {
      [ImageTypes.FRONT_LEFT_ANGLE]: FrontLeftVanViewOverlayLandscape,
      [ImageTypes.REAR_RIGHT_ANGLE]: RearRightVanViewOverlayLandscape,
    },
  };

  const sedanMasks: MaskCollection = {
    [Orientation.PORTRAIT]: {
      [ImageTypes.FRONT_LEFT_ANGLE]: FrontLeftViewOverlayPortrait,
      [ImageTypes.REAR_RIGHT_ANGLE]: RearRightViewOverlayPortrait,
    },
    [Orientation.LANDSCAPE]: {
      [ImageTypes.FRONT_LEFT_ANGLE]: FrontLeftViewOverlayLandscape,
      [ImageTypes.REAR_RIGHT_ANGLE]: RearRightViewOverlayLandscape,
    },
  };

  const portraitMask = (
    isTransporterBodyType
      ? transporterMasks[Orientation.PORTRAIT][imageType]
      : sedanMasks[Orientation.PORTRAIT][imageType]
  ) as FunctionComponent<{}>;

  const landscapeMask = (
    isTransporterBodyType
      ? transporterMasks[Orientation.LANDSCAPE][imageType]
      : sedanMasks[Orientation.LANDSCAPE][imageType]
  ) as FunctionComponent<{}>;

  return { portraitMask, landscapeMask };
};
