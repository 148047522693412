// for camera testing on local phone
const isLocalRunWithStagingApi = false;

export const getApiUrl = () => {
  if (isLocalRunWithStagingApi) {
    return process.env.REACT_APP_B2B_API_URL_STAGING;
  }

  return process.env.REACT_APP_B2B_API_URL;
};

export const getRFPath = () => {
  if (process.env.REACT_APP_RF_PATH) {
    return process.env.REACT_APP_RF_PATH;
  }

  if (isLocalRunWithStagingApi) {
    if (!process.env.REACT_APP_RF_PATH) {
      throw new Error('RF Path should be specified in env REACT_APP_RF_PATH');
    }
    return process.env.REACT_APP_RF_PATH;
  }

  return window.location.href.split('?')[0];
};
