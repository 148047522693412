import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { accidentPoliceReportPageCompleted } from '../actions';
import { AccidentPoliceReportReducer } from '../types';

const initialState: AccidentPoliceReportReducer = {
  officerName: '',
  reportNumber: '',
};

export const accidentPoliceReportReducer = createReducer(initialState, {
  [accidentPoliceReportPageCompleted.type]: (
    state,
    action: PayloadAction<AccidentPoliceReportReducer>,
  ) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});
